const colors = { 
	navy: "#001f3f",
	blue: "#0074D9",
  aliceblue: "#F0F8ff",
	lightblue: "#66ace8",
	darkblue : "#104970",
	aqua: "#7FDBFF",
	teal: "#39CCCC",
	olive: "#3D9970",
	green: "#2ECC40",
  lightgreen: "#36E675",
	lime: "#01FF70",
	yellow: "#FFDC00",
	gold: "#ffca00",
  lightorange: "#FFF1E4",
	orange: "#FF851B",
	darkorange:'#D76A03',
  lightred: "#ff3538",
	red: "#FF4136",
	darkred: "#8B0000",
	maroon: "#85144b",
	fuchsia: "#F012BE",
	purple: "#B10DC9",
	black: "#111111",
	lightgray: "#EEEEEE",
	gray: "#AAAAAA",
	mediumgray:"#777777",
	darkgray: "#333333",
	silver: "#DDDDDD",
	white: "#FEFEFE",
  darkwhite: "#F4F4F4",
	darkerGray: "#A5A5A5",
	charcoal: '#36454f',
	pampas:'#ECE4DB'
}

const theme = {
	// Colors
	...colors,
	// Elements
	background: colors.silver,
	thumbnail: colors.teal,
	thumbnailText: colors.white,
	navbar: colors.navy,
	navbarText: colors.white,
	draftBackground: "#D4D4D4",
	// UI
	ui : {
		blue : "#156499",
		blueDarker : "#104970",
    bluegrey : "#E9E9EB",
		darkblue : "#104970",
		orange : "#F9AF16",
		grey: "#d3d3d3"
	},
	specific:{
		red: {
			light:'#ffecea',
			regular:'#FF4136',
			dark:'#7f201b'
		},
		blue: {
			light:'#e7eff4',
			regular:'#156499',
			dark:'#0c3c5b',
      opacity:'rgba(21, 100, 153, 0.5)'
		},
		yellow: {
			light:'#fff5d1',
			regular:'#FFCA00',
			dark:'#997900'
		},
		green: {
			light:'#eaf9eb',
			regular:'#2ECC40',
			dark:'#1b7a26'
		},
		purple: {
			light:'#f7e6f9',
			regular:'#b10dc9',
			dark:'#6a0778'
		},
		orange: {
			light:'#fff2e8',
			regular:'#ff851b',
			dark:'#1b7a26'
		},
		teal: {
			light: '#ebf9f9',
			regular: '#39CCCC',
			dark: '#1c6666'
		},
		lime: {
			light: '#e5fff0',
			regular: '#01FF70',
			dark: '#009943'
		},
		pink: {
			light: '#ffe5f3',
			regular: '#FF0190',
			dark: '#7f0048'
		},
		wood: {
			light:'#f9f2ec',
			regular: '#C68642',
			dark: '#634321'
		}
	},
	notif : {
		info : "#338ba695",
		success : "#66ac6395",
		warning : "#f79e2095",
		error : "#c14a4395"
	},
	satisfaction: {
		veryHappy: "#60c968",
		happy: "#9fc45f",
		neutral: "#ffc560",
		sad: "#ffa360",
		verySad: "#fc374e"
	},
  // Button theme
  button : {
    confirm : colors.green,
    cancel : colors.yellow,
    exit : colors.yellow,
    interact : colors.blue
  }
}


export default theme;
