import { defineMessages } from "react-intl";
export default defineMessages({
  description: {
    id: "a2vote.description",
    defaultMessage: "Description",
  },
  return: {
    id: "a2vote.return",
    defaultMessage: "Return",
  },
  send: {
    id: "a2vote.send",
    defaultMessage: "Send",
  },
  title: {
    id: "a2vote.title",
    defaultMessage: "Title",
  },
  users: {
    id: "a2vote.users",
    defaultMessage: "Users",
  },
  admins: {
    id: "a2vote.admins",
    defaultMessage: "Admins",
  },
  add: {
    id: "a2vote.add",
    defaultMessage: "Add",
  },
  user_join: {
    id: "a2vote.user_join",
    defaultMessage: "You have joined the list. You can now close this page.",
  },
  user_already_join: {
    id: "a2vote.user_already_join",
    defaultMessage: "You have already joined the list. You can now close this page.",
  },
  user_not_validate: {
    id: "a2vote.user_not_validate",
    defaultMessage: "* The user has not yet validated his registration",
  },
  user_complete: {
    id: "a2vote.user_complete",
    defaultMessage: "To join the list, please complete your registration",
  },
  user_mail: {
    id: "a2vote.user_mail",
    defaultMessage: "Mail",
  },
  user_password: {
    id: "a2vote.user_password",
    defaultMessage: "Password",
  },
  user_confirm_password: {
    id: "a2vote.user_confirm_password",
    defaultMessage: "Confirm password",
  },
  user_link_not_valid: {
    id: "a2vote.user_link_not_valid",
    defaultMessage:
      "This link is no longer valid. Please request access from your adminstrator",
  },
  user_error_field_1: {
    id: "a2vote.user_error_field_1",
    defaultMessage: "Please enter all fields",
  },
  user_error_field_2: {
    id: "a2vote.user_error_field_2",
    defaultMessage: "Passwords do not match",
  },
  new : {
    id: "a2vote.userlist.new",
    defaultMessage: "New users list",
  },
  user : {
    id: "a2vote.userlist.user",
    defaultMessage: "User",
  },
  admin : {
    id: "a2vote.userlist.admin",
    defaultMessage: "Admin",
  },
  loading : {
    id: "a2vote.loading",
    defaultMessage: "Loading",
  },
  userlist_title : {
    id: "a2vote.userlist.new",
    defaultMessage: "New users list",
  },
  userlist_edit : {
    id: "a2vote.userlist.edit",
    defaultMessage: "Edit users list",
  },
  userlist_editlist : {
    id: "a2vote.userlist.editlist",
    defaultMessage: "Edit list",
  },
  userlist_add : {
    id: "a2vote.userlist.add",
    defaultMessage: "Add list",
  },
  userlist_delete : {
    id: "a2vote.userlist.delete",
    defaultMessage: "Delete users list",
  },
  userlist_import_button : {
    id: "a2vote.userlist.import.button",
    defaultMessage: "Import a file",
  },
  userlist_import_error : {
    id: "a2vote.userlist.import.error",
    defaultMessage: "The file does not have the correct headers, please check them",
  },
  more_parameter : {
    id: "a2vote.userlist.more.parameter",
    defaultMessage: "Advanced parameters",
  },
  secure_groups : {
    id: "a2vote.userlist.secure_groups",
    defaultMessage: "Secure Groups"
  },
  secure_groups_desc : {
    id: "a2vote.userlist.secure_groups_desc",
    defaultMessage: "Secure Groups"
  },
  hidden_name : {
    id: "a2vote.userlist.hidden.name",
    defaultMessage: "Hidden name"
  },
});
