import React from 'react';
// Tools
import styled from 'styled-components';
import theme from 'theme';

const All = styled.div`
  display: flex;
  user-select: none;
`;

const A2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background: ${theme.blue};
  color: ${theme.white};
  font-size: 56px;
  border-radius: 8px;
  font-family: 'Raleway', sans-serif;

  ${ props => props.small && `
    height: 38px;
    width: 38px;
    font-size: 28px;
  `}
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  border: 1px solid ${theme.blue};
  border-left: none;
  color: ${theme.blue};
  font-size: 56px;
  border-radius: 0 8px 8px 0;
  font-family: 'Raleway', sans-serif;
  margin-left: -24px;
  padding: 0 24px 0 36px;

  ${ props => props.small && `
    height: 36px;
    font-size: 28px;
    padding: 0 10px 0 30px;
  `}
`;

const A2Logo = ({small}) => {
  return (
    <All>
      <A2 small={small}>
        <div>a2</div>
      </A2>
      <Brand small={small}>Vote</Brand>
    </All>
  )
}

export default A2Logo;